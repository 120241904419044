import { graphql } from "gatsby"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import NewsDetailsBanner from "../components/NewsDetailsBanner/NewsDetailsBanner"
import NewsImageBlock from "../components/NewsImageBlock/NewsImageBlock"
import NewsSlider from "../components/NewsSlider/NewsSlider"
import NewsStaticComponent from "../components/NewsStaticComponent/NewsStaticComponent"
import NewsTextBlock from "../components/NewsTextBlock/NewsTextBlock"
import PodCastListing from "../components/PodCastListing/PodCastListing"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logoBlack from "../images/logo-white.svg"
import { Script } from "gatsby"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
const { GetUrlSet } = require("@starberry/gatsby-theme-utils/Common/GGFXClient/Client") 



const NewsDetails = ({ data, pageContext}) => {
  // we are assign this to state since we going to conti the data flow when using preview mode

  const windowSearch= typeof window!=="undefined"?window.location.search:""

  const [pageData, setPageData] = useState(data?.strapiBlog);
  // const [loading,setLoading] = useState(true)
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string
  const [instaScript, setInstaScript] = useState(false);

  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const blogData = pageData
  const blogArr = data?.allStrapiBlog?.edges

  const [shareIcons, setShareIcons] = useState(false)

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  // there will be url params just like preview option, ex., ?debug=preview so these aren't suppose to be in the canonical or structured data
  //let myurl = new URL(pageurl).searchParams.delete('debug');

  const category =
    blogData?.blog_categories?.length > 0 && blogData?.blog_categories[0].slug


  const filtered = _.filter(blogArr, function (o) {
    if (o.node.blog_categories?.length > 0) {
      return o.node.blog_categories?.some(item => item.slug === category)
    }
  })

  const splitUrl = pageurl.split("/")
  const currentNews = splitUrl[splitUrl.length - 2]

  const sliderNews = filtered?.filter(item => {
    return item.node?.slug !== currentNews
  })

  const PageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  var ldJson = {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": pageurl,
    },
    headline: blogData?.title,
    image: [blogData?.banner_image?.url],
    datePublished: blogData?.publishedAt,
    dateModified: blogData?.updatedAt,
    author: {
      "@type": "Person",
      name: blogData?.published_by?.name,
    },
    publisher: {
      "@type": "Organization",
      name: "hand & haus",
      logo: {
        "@type": "ImageObject",
        url: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png",
      },
    },
    description: `Read about ${blogData?.title} here and subscribe to our newsletter to stay up-to-date about everything going on at haus & haus.`,
  }

  useEffect(()=>{
    const getClassName = document.getElementsByClassName("instagram-media")
    if(getClassName?.length>0){
      setInstaScript(true)
    }
  },[])

  useEffect(() => {
    // Check if the preview query parameter is present
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
// make this only when preview params available
    if (isPreview) {
      // to structure this query you have to refer the graphQL mentioned in the below and the localhost graphql playground //http://localhost:8000/__graphql
      // more into about this: https://docs.strapi.io/dev-docs/api/rest/populate-select#relations--media-fields
      const apiUrl = process.env.GATSBY_STRAPI_SRC+'/api/blogs/'+urlParams.get('strapi_id')+'?populate[0]=banner_image&populate[1]=tile_image&populate[2]=card_list.items&populate[3]=news_detail_member&populate[4]=published_by&populate[5]=blog_categories&populate[6]=news_detail_member.image&populate[7]=published_by.image&populate[8]=blog_content&populate[9]=blog_content.image&populate[10]=blog_content.items&populate[11]=blog_content.items.image&populate[12]=card_list.items.image';

      const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

      const fetchData = async () => {
        try {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });
          const previewData = await response.json();
          setLoading(false)
          setEnablePreview(false)
          let myPrevData;
          if(pageData){
            myPrevData = pageData
          } else {
            myPrevData = {};
            myPrevData.banner_image = {}
            myPrevData.content = {}
            myPrevData.content.data = {}
            myPrevData.card_list = {}
            myPrevData.tile_image = {}
            myPrevData.news_detail_member = {}
            myPrevData.news_detail_member.content = {}
            myPrevData.news_detail_member.content.data = {}
            myPrevData.news_detail_member.image = {}
            myPrevData.published_by = {}
            myPrevData.published_by.image = {}
            myPrevData.blog_categories = []
            myPrevData.blog_categories[0] = {}
            myPrevData.id = urlParams.get('strapi_id')

          }
          if(previewData){
            setIsPreviewEnabled(true)
            //skip fields like id, which are dont need to be updated
            myPrevData.banner_image.caption = previewData?.data?.attributes?.banner_image?.data?.attributes?.caption
            myPrevData.banner_image.url = previewData?.data?.attributes?.banner_image?.data?.attributes?.url
            myPrevData.content.data.content = previewData?.data?.attributes?.content
            myPrevData.imagetransforms = previewData?.data?.attributes?.imagetransforms
            myPrevData.publishedAt = previewData?.data?.attributes?.publishedAt
            myPrevData.updatedAt = previewData?.data?.attributes?.updatedAt
            myPrevData.read_time = previewData?.data?.attributes?.read_time
            myPrevData.date = previewData?.data?.attributes?.date
            myPrevData.slug = previewData?.data?.attributes?.slug
            myPrevData.tile_image.url = previewData?.data?.attributes?.tile_image?.data?.attributes?.url
            myPrevData.title = previewData?.data?.attributes?.title
            myPrevData.video_url = previewData?.data?.attributes?.video_url
            myPrevData.card_list.items = previewData?.data?.attributes?.card_list?.items // array
            myPrevData.isFormOpen = previewData?.data?.attributes?.isFormOpen
            myPrevData.form_title = previewData?.data?.attributes?.form_title

            if(previewData?.data?.attributes?.news_detail_member){
              myPrevData.news_detail_member.content.data.content = previewData?.data?.attributes?.news_detail_member?.content
              myPrevData.news_detail_member.designation = previewData?.data?.attributes?.news_detail_member?.designation
              myPrevData.news_detail_member.name = previewData?.data?.attributes?.news_detail_member?.name
              myPrevData.news_detail_member.image.url = previewData?.data?.attributes?.news_detail_member?.image?.data?.attributes?.url
            }
            if(previewData?.data?.attributes?.published_by?.data){
              myPrevData.published_by.designation = previewData?.data?.attributes?.published_by?.data?.attributes?.designation
              myPrevData.published_by.email = previewData?.data?.attributes?.published_by?.data?.attributes?.email
              myPrevData.published_by.name = previewData?.data?.attributes?.published_by?.data?.attributes?.name
              myPrevData.published_by.phone = previewData?.data?.attributes?.published_by?.data?.attributes?.phone
              myPrevData.published_by.slug = previewData?.data?.attributes?.published_by?.data?.attributes?.slug
              myPrevData.published_by.image.url = previewData?.data?.attributes?.published_by?.data?.attributes?.image?.data?.attributes?.url
            }
            if(previewData?.data?.attributes?.blog_categories?.data){
              myPrevData.blog_categories[0].blog_category = previewData?.data?.attributes?.blog_categories?.data[0]?.attributes?.blog_category
              myPrevData.blog_categories[0].slug = previewData?.data?.attributes?.blog_categories?.data[0]?.attributes?.slug
            }
            myPrevData.blog_content = previewData?.data?.attributes?.blog_content

            //merge the new data into the original data so that we dont have to worry about the template flow
            setPageData(pageData => ({ ...pageData, myPrevData }));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } 
    else {
      setLoading(false);
      setIsPreviewEnabled(false)
    }
  }, []); // Empty dependency array ensures the effect runs only once

  // if (typeof window !== "undefined") {
    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }

    if(enablePreview){
      return null
    }

  //breadcrumb schema
    let dynamicmenu1 ="Home"
    let dynamicmenu1Url = "https://www.hausandhaus.com/"
    let dynamicmenu2 = "Resources"
    let dynamicmenu2Url="https://www.hausandhaus.com/resources/"
    let dynamicmenu3 = "Latest News and Insights"
    let dynamicmenu3Url = "https://www.hausandhaus.com/resources/latest-news-and-insights/"
    let dynamicmenu4 = blogData.title
    let dynamicmenu4Url = pageurl
    
  
    
  
    let breadcrumbJson = {
    "@context": "https://schema.org",	
    "@type": "BreadcrumbList",
    itemListElement: [	
      {	
        "@type": "ListItem",	
        position: 1,	
        name: dynamicmenu1,	
        item: dynamicmenu1Url	
      },	
    ]	
    }
  
    if(dynamicmenu2){
      breadcrumbJson.itemListElement.push(
        {
          "@type": "ListItem",	
          position: 2,	
          name: dynamicmenu2,	
          item: dynamicmenu2Url	
        }
      )
    }
  
    if(dynamicmenu3){
      breadcrumbJson.itemListElement.push(
        {
          "@type": "ListItem",	
          position: 3,	
          name: dynamicmenu3,	
          item: dynamicmenu3Url	
        }
      )
    } 

    if(dynamicmenu4){
      breadcrumbJson.itemListElement.push(
        {
          "@type": "ListItem",	
          position: 4,	
          name: dynamicmenu4,	
          item: dynamicmenu4Url	
        }
      )
    } 
  
  //

  return (
    <Layout popUpData={popUpData} nonpropertyDetails dark={true}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(ldJson, null, 2)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbJson, null, 2)}
        </script>
      </Helmet>
      {instaScript&&
        <Script async src="https://www.instagram.com/embed.js"/>
      }
      <div className="layout-padding-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            mainparentname={`Resources`}
            mainparent={PageLinks.resources}
            parentname={`Latest News and Insights`}
            type={"details-page"}
            parent={PageLinks.news}
            pagename={blogData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>
      {blogData && <NewsDetailsBanner blogData={blogData} />}
      {blogData?.blog_content?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" && (
              <PodCastListing podcastsList={item} />
            )}
            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TEXT_BLOCK" || 
              item?.__component === "components.text-block") && (
              <NewsTextBlock isPreviewEnabled={isPreviewEnabled} newsContent={item} />
            )}
            {(item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_IMAGE" ||
            item?.__component === "page-modules.image")&& (
              <NewsImageBlock isPreviewEnabled={isPreviewEnabled} imageData={item} blogData={blogData} />
            )}
          </div>
        )
      })}

      {blogData && <NewsStaticComponent isPreviewEnabled={isPreviewEnabled} blogData={blogData} />}
      {sliderNews?.length > 0 && (
        <NewsSlider
          className="news-details-slider"
          data={sliderNews?.slice(0, 4)}
          title="You might also be interested in..."
        />
      )}
    </Layout>
  )
}
// }

export const Head = ({ data }) => {

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }
  const blogData = data.strapiBlog

  let myimgtransforms = ""
  if (blogData?.imagetransforms?.tile_image_Transforms) {
    myimgtransforms = JSON.parse(
      blogData?.imagetransforms?.tile_image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (blogData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms1 = JSON.parse(blogData?.imagetransforms?.metaog_Transforms)
  }
  
  let getImg=blogData?.banner_image?.url?blogData?.banner_image?.url:""
  const findImage = getImg?.substring(getImg?.indexOf("i"));
  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""
  
  let imageUrl=""

  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }
  else if(myimgtransforms){
    // ogRender()
    const ProcessedResultWebp=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    const ProcessedResultExt=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage][ext]:""
    const ProcessedResult=ProcessedResultExt?ProcessedResultExt["304x200"]:ProcessedResultWebp?ProcessedResultWebp["304x200"]:blogData?.banner_image?.url
    imageUrl=ProcessedResult
  }

  //new ggfx image
  const getImage =
  blogData?.banner_image?.url
      ? blogData?.banner_image.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && blogData?.ggfx_results?.length > 0
      ? blogData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""

  // filter image with image size
  const getOGImg =
    filterImage.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: blogData.strapi_id,
      field: "metaog",
      contentType: "blog"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  

  const pageImage=getOGImg?.length>0?getOGImg[0].url:""


  const metaTitle = blogData?.title
  const metaDesc = `Read about ${blogData?.title} here and subscribe to our newsletter to stay up-to-date about everything going on at haus & haus.`

  return(
    <>
      <SEO isPreview={isPreview} title={metaTitle} description={metaDesc} imageUrl={imageUrl?imageUrl:pageImage}></SEO>
      
    </>
  ) 
}

export default NewsDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiBlog(strapi_id: { eq: $page_id }) {
      banner_image {
        url
        caption
      }
      content {
        data {
          content
        }
      }
      isFormOpen
      email_admin_subject
      email_id
      email_user_subject
      form_title
      mail_user_content {
        data {
          mail_user_content
        }
      }
      ggfx_results {
        src_cftle
        transforms {
          url
          transform
          format
        }
      }

      blog_categories {
        blog_category
        slug
      }

      imagetransforms {
        tile_image_Transforms
        banner_image_Transforms
        metaog_Transforms
      }

      publishedAt
      updatedAt
      id
      read_time
      date
      slug
      strapi_id
      tile_image {
        url
      }
      title

      video_url
      published_by {
        image {
          url
        }
        name
        phone
        designation
        email
        slug
      }
      card_list {
        items {
          cta_title
          content {
            data {
              content
            }
          }
          custom_link
          image {
            alternativeText
            url
          }
          link_type
        }
      }
      
      blog_content {
        ... on STRAPI__COMPONENT_COMPONENTS_TEXT_BLOCK {
          id
          __typename
          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
          id
          __typename
          heading
          items {
            cta_title
            custom_link
            link_type
            image {
              alternativeText
              url
            }
            content {
              data {
                content
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
          id
          __typename
          image {
            url
          }
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          strapi_id
          read_time
          date
          tile_image {
            url
          }
          imagetransforms {
            tile_image_Transforms
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }

          blog_categories {
            blog_category
            slug
          }
        }
      }
    }

    allStrapiPage(filter: { layout: { eq: "news_landing_page" } }) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
